

































import Vue from 'vue'
import axios from '@/axios-instance'
import { mapActions } from 'vuex'

export default Vue.extend({
  props: {
    token: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      form: {
        password: '',
      },
      showPassword: false,
    }
  },
  methods: {
    ...mapActions(['setGlobalToast']),
    submit() {
      const formData = new FormData()
      formData.append('token', this.token)
      formData.append('password', this.form.password)

      axios
        .post('/recover', formData)
        .then((response) => {
          if (response.success) {
            this.setGlobalToast({
              type: 'success',
              content: 'Votre mot de passe a été modifié avec succès.',
              title: 'Merci !',
            })
          }

          this.$router.push({ name: 'home' })
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.setGlobalToast({
              type: 'danger',
              content:
                "Impossible de modifier votre mot de passe, le jeton de vérification n'est plus valide ou incorrect. Merci de réessayer à nouveau.",
              title: 'Erreur !',
            })
          }

          this.$router.push({ name: 'home' })
        })
    },
    togglePasswordFieldDisplay(): void {
      this.showPassword = !this.showPassword
    },
  },
})
